import React from "react";

import Form from "./form";
import { ContactsContainer } from "./Contacts.styles";
import GoogleMaps from "components/googleMaps";
const Contacts = (props) => {
  const {
    formEmailContacto,
    data: {
      addMap,
      contAddress,
      contLatitude,
      contLongitude,
      contPhones,
      contTextEmail,
      contTextSchedule,
      contEmail,
      linkMapsConcessionario,
      contTitle
    },
    place
  } = props;

  //console.log(props);

  return (
    <>
      <ContactsContainer id="contactos">
        <div className="container">
          <h2>{contTitle}</h2>
          {addMap && (
            <GoogleMaps
              linkMapsConcessionario={linkMapsConcessionario}
              latitude={contLatitude}
              longitude={contLongitude}
              address={contAddress}
            />
          )}
          <p>{contTextSchedule}</p>
          <div className="contacts-list">
            {contPhones?.map((phone, index) => {
              return (
                <a
                  key={index}
                  href={"tel:+351" + phone.phone.replace(/\s/g, "")}
                >
                  <img src="/images/call.png" alt="Call Logo" />
                  <span>{phone.phone}</span>
                </a>
              );
            })}
          </div>
          <p>{contTextEmail}</p>
          <div className="contacts-list">
            {contEmail?.map((mail, index) => {
              return (
                <a key={index} href={"mailto:" + mail.email}>
                  <img src="/images/email.png" alt="Email Logo" />
                  <span>{mail.email}</span>
                </a>
              );
            })}
          </div>
        </div>
      </ContactsContainer>
      <Form
        formEmailContacto={formEmailContacto}
        title={props.title}
        place={place}
      />
    </>
  );
};

export default Contacts;
